"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperandValues = void 0;
var OperandValues;
(function (OperandValues) {
    OperandValues["any"] = "any";
    OperandValues["all"] = "all";
    OperandValues["age"] = "age";
    OperandValues["gender"] = "gender";
    OperandValues["diagnosis"] = "diagnosis";
    OperandValues["noPreviousTest"] = "noPreviousTest";
    OperandValues["noPreviousDoS"] = "noPreviousDoS";
    OperandValues["noTestWithin"] = "noTestWithin";
    OperandValues["noDoSWithin"] = "noDoSWithin";
    OperandValues["noEncounterWithin"] = "noEncounterWithin";
    OperandValues["noTestResultDateWithin"] = "noTestResultDateWithin";
    OperandValues["race"] = "race";
    OperandValues["packYears"] = "packYears";
    OperandValues["packYearsSocialHistory"] = "packYearsSocialHistory";
    OperandValues["smokingStatus"] = "smokingStatus";
    OperandValues["smokingStatusSocialHistory"] = "smokingStatusSocialHistory";
    OperandValues["smokingCessationYears"] = "smokingCessationYears";
    OperandValues["smokingCessationYearsSocialHistory"] = "smokingCessationYearsSocialHistory";
    OperandValues["testResultValue"] = "testResultValue";
    OperandValues["bloodPressure"] = "bloodPressure";
    OperandValues["fluSeason"] = "fluSeason";
    OperandValues["vaccineAdministered"] = "vaccineAdministered";
    OperandValues["ageOfFirstVaccine"] = "ageOfFirstVaccine";
    OperandValues["primaryInsuranceName"] = "primaryInsuranceName";
})(OperandValues = exports.OperandValues || (exports.OperandValues = {}));
