"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventDetailType = void 0;
/**
 * Used for Eventbridge Event Bus Event Rules and their
 * invocations using the eventPattern.detailType property
 */
var EventDetailType;
(function (EventDetailType) {
    EventDetailType["GRITS_REFRESH"] = "grits-refresh";
    EventDetailType["SYNC_LOOKUPS"] = "sync-lookups";
    EventDetailType["PATIENT_UPDATE"] = "patient-update";
    EventDetailType["INITIAL_SYNC"] = "initial-sync";
})(EventDetailType = exports.EventDetailType || (exports.EventDetailType = {}));
