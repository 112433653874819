"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientUpdateType = void 0;
/**
 * Patient Update Types (Async Process)
 *
 * These are the types of updates that can be performed on a patient.
 *
 * These are submitted to the eventbridge as the DetailType and
 * parsed in the patient-update lambda function to determine the
 * relevant data type.
 */
var PatientUpdateType;
(function (PatientUpdateType) {
    PatientUpdateType["AllergiesUpdate"] = "AllergiesUpdate";
    PatientUpdateType["AOEUpdate"] = "AOEUpdate";
    PatientUpdateType["ChiefComplaintsUpdate"] = "ChiefComplaintsUpdate";
    PatientUpdateType["EncounterROSDataUpdate"] = "EncounterROSDataUpdate";
    PatientUpdateType["EncounterUpdate"] = "EncounterUpdate";
    PatientUpdateType["FamilyHistoryUpdate"] = "FamilyHistoryUpdate";
    PatientUpdateType["GynHistoryUpdate"] = "GynHistoryUpdate";
    PatientUpdateType["HospitalizationsUpdate"] = "HospitalizationsUpdate";
    PatientUpdateType["HPIUpdate"] = "HPIUpdate";
    PatientUpdateType["ImmunizationItemAdd"] = "ImmunizationItemAdd";
    PatientUpdateType["ImmunizationItemUpdate"] = "ImmunizationItemUpdate";
    PatientUpdateType["MedicalHistoryUpdate"] = "MedicalHistoryUpdate";
    PatientUpdateType["MedicationUpdate"] = "MedicationUpdate";
    PatientUpdateType["ObHistoryUpdate"] = "ObHistoryUpdate";
    PatientUpdateType["OrderableItemAdd"] = "OrderableItemAdd";
    PatientUpdateType["OrderableItemUpdate"] = "OrderableItemUpdate";
    PatientUpdateType["PatientStickyNoteUpdate"] = "PatientStickyNoteUpdate";
    PatientUpdateType["ProblemDelete"] = "ProblemDelete";
    PatientUpdateType["SocialHistoryUpdate"] = "SocialHistoryUpdate";
    PatientUpdateType["SurgeriesUpdate"] = "SurgeriesUpdate";
    PatientUpdateType["TelephoneEncounterUpdate"] = "TelephoneEncounterUpdate";
    PatientUpdateType["VitalsUpdate"] = "VitalsUpdate";
})(PatientUpdateType = exports.PatientUpdateType || (exports.PatientUpdateType = {}));
