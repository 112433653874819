import { Operand } from 'types';
import {
  initialDiagnosis,
  initialAll,
  initialAny,
  initialAge,
  initialGender,
  initialNoTestWithin,
  initialNoPreviousTest,
  initialRace,
  initialNoPreviousDoS,
  initialNoDoSWithin,
  initialNoEncounterWithin,
  initialPackYears,
  initialPackYearsSocialHistory,
  initialSmokingStatus,
  initialSmokingStatusSocialHistory,
  initialSmokingCessationYears,
  initialSmokingCessationYearsSocialHistory,
  initialTestResultValue,
  initialNoTestResultDateWithin,
  initialBloodPressure,
  initialFluSeason,
  initialVaccineAdministered,
  initialAgeOfFirstVaccine,
  initialPrimaryInsuranceName,
} from './InitialValues';

// the value is an empty "initialObject" that we use to set the initial value of the operand
export const operandTypes: { label: string; value: Operand }[] = [
  { label: 'All', value: initialAll },
  { label: 'Any', value: initialAny },
  { label: 'Age', value: initialAge },
  { label: 'Age of First Vaccination', value: initialAgeOfFirstVaccine },
  { label: 'Blood Pressure Results', value: initialBloodPressure },
  { label: 'Diagnosis', value: initialDiagnosis },
  { label: 'Gender', value: initialGender },
  { label: 'Flu Season', value: initialFluSeason },
  { label: 'No Previous Test', value: initialNoPreviousTest },
  { label: 'No Previous DoS', value: initialNoPreviousDoS },
  { label: 'No Test Within', value: initialNoTestWithin },
  { label: 'No DoS Within', value: initialNoDoSWithin },
  { label: 'No Encounter Within', value: initialNoEncounterWithin },
  { label: 'No Test Result Date Within', value: initialNoTestResultDateWithin },
  { label: 'Pack Years', value: initialPackYears },
  { label: 'Pack Years Social History', value: initialPackYearsSocialHistory },
  { label: 'Primary Insurance Name', value: initialPrimaryInsuranceName },
  { label: 'Primary Race', value: initialRace },
  { label: 'Smoking Cessation Years', value: initialSmokingCessationYears },
  {
    label: 'Smoking Cessation Years Social History',
    value: initialSmokingCessationYearsSocialHistory,
  },
  { label: 'Smoking Status', value: initialSmokingStatus },
  { label: 'Smoking Status Social History', value: initialSmokingStatusSocialHistory },
  { label: 'Test Result Value', value: initialTestResultValue },
  { label: 'Vaccines Administered', value: initialVaccineAdministered },
];
