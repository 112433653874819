"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SynchronousRequestType = void 0;
/**
 * Synchronous Patient Update Request Types
 *
 * These are the types of updates that can be performed on a patient.
 *
 * These are as part of the SynchronousUpdateRequestFunction type.
 * Called Request Type instead of Update since not all operations
 * are updates.
 */
var SynchronousRequestType;
(function (SynchronousRequestType) {
    SynchronousRequestType["addEncounterPastResult"] = "addEncounterPastResult";
    SynchronousRequestType["addImmunizationInjection"] = "addImmunizationInjection";
    SynchronousRequestType["addMedicalHistoryItems"] = "addMedicalHistoryItems";
    SynchronousRequestType["addOrderableItem"] = "addOrderableItem";
    SynchronousRequestType["addTelephoneEncounter"] = "addTelephoneEncounter";
    SynchronousRequestType["applyTemplateToEncounter"] = "applyTemplateToEncounter";
    SynchronousRequestType["declineImmunizationInjection"] = "declineImmunizationInjection";
    SynchronousRequestType["deleteImmunizationInjection"] = "deleteImmunizationInjection";
    SynchronousRequestType["deleteOrderableItem"] = "deleteOrderableItem";
    SynchronousRequestType["getEncounterAssessments"] = "getEncounterAssessments";
    SynchronousRequestType["getEncounterMedicationsExternal"] = "getEncounterMedicationsExternal";
    SynchronousRequestType["getTemplatePreview"] = "getTemplatePreview";
    SynchronousRequestType["removeEncounterPastResult"] = "removeEncounterPastResult";
    SynchronousRequestType["submitOrderableItemLabs"] = "submitOrderableItemLabs";
    SynchronousRequestType["updateEncounter"] = "updateEncounter";
    SynchronousRequestType["updateImmunizationInjection"] = "updateImmunizationInjection";
    SynchronousRequestType["updateOrderableItem"] = "updateOrderableItem";
    SynchronousRequestType["updatePharmacy"] = "updatePharmacy";
    SynchronousRequestType["updateUserTemplateFavorites"] = "updateUserTemplateFavorites";
})(SynchronousRequestType = exports.SynchronousRequestType || (exports.SynchronousRequestType = {}));
