import { AlertColor } from '@mui/material';

export interface Toast {
  //type: 'success' | 'info' | 'warning' | 'error'; // this should match the Snackbar variant type
  alertColor: AlertColor;
  message: string;
  timeSent: number; // we need this to distinguish between each toast in case the message is the same
}

export interface Location {
  locationID: number;
  locationName: string;
}

export interface Staff {
  staffId: number;
  staffName: string;
}

export enum RecommendationType {
  IMAGING = 'IMAGING',
  IMMUNIZATION = 'IMMUNIZATION',
  INJECTION = 'INJECTION',
  LAB = 'LAB',
  PROCEDURE = 'PROCEDURE',
}

export interface OrderableItemOption {
  orderableItemId: number;
  orderableItemName: string;
}

export interface Recommendation {
  criteria: string;
  defaultIcdCode?: string;
  defaultDiagnosisName?: string;
  defaultAssessmentItemId?: number;
  description: string;
  displayOrder: number;
  futureOrder?: boolean;
  orderableItemId: number;
  orderableItemOptions?: OrderableItemOption[];
  orderableItemTypeId: number;
  orderableItemName: string;
  isOrderable: boolean;
  radTech: boolean;
  recommendationType: RecommendationType;
  required: boolean;
  resultAttribute?: string;
  resultAttributeId?: number;
}

export type Operand =
  | AllOperand
  | AnyOperand
  | AgeOperand
  | GenderOperand
  | RaceOperand
  | DiagnosisOperand
  | NoPreviousTestOperand
  | NoPreviousDoSOperand
  | NoTestWithinOperand
  | NoDoSWithinOperand
  | NoEncounterWithinOperand
  | NoTestResultDateWithinOperand
  | PackYearsOperand
  | PackYearsSocialHistoryOperand
  | SmokingStatusOperand
  | SmokingStatusSocialHistoryOperand
  | SmokingCessationYearsOperand
  | SmokingCessationYearsSocialHistoryOperand
  | TestResultValueOperand
  | BloodPressureOperand
  | FluSeasonOperand
  | VaccineAdministeredOperand
  | AgeOfFirstVaccineOperand
  | PrimaryInsuranceNameOperand;

export interface AllOperand {
  operator: 'all';
  value: Operand[];
  operand: OperandValues.all;
}

export interface AnyOperand {
  operator: 'any';
  value: Operand[];
  operand: OperandValues.any;
}

export interface AgeOperand {
  operator: ConditionalOperatorType;
  value: number;
  operand: OperandValues.age;
}

export interface GenderOperand {
  operator: '=';
  value: 'M' | 'F' | 'U';
  operand: OperandValues.gender;
}

export interface RaceOperand {
  operator: '=' | '!';
  value: string;
  operand: OperandValues.race;
}

export interface DiagnosisOperand {
  operator: '=' | '!';
  diagnosisGroupSourceId: string;
  operand: OperandValues.diagnosis;
}

export interface NoPreviousTestOperand {
  orderableLabItemId: string;
  testType: RecommendationType;
  operand: OperandValues.noPreviousTest;
}

export interface NoPreviousDoSOperand {
  operand: OperandValues.noPreviousDoS;
  DoSPropertyName: DoSPropertyName;
}

export interface NoTestWithinOperand {
  operand: OperandValues.noTestWithin;
  orderableLabItemId: string;
  duration: DurationObject;
  testType: RecommendationType;
}

export interface NoDoSWithinOperand {
  operand: OperandValues.noDoSWithin;
  DoSPropertyName: DoSPropertyName;
  duration: DurationObject;
}

export interface NoEncounterWithinOperand {
  duration: DurationObject;
  operand: OperandValues.noEncounterWithin;
}

export interface PackYearsOperand {
  operator: ConditionalOperatorType;
  value: number;
  operand: OperandValues.packYears;
}

export interface PackYearsSocialHistoryOperand {
  operator: ConditionalOperatorType;
  value: number;
  operand: OperandValues.packYearsSocialHistory;
}

export interface SmokingStatusOperand {
  operator: '=' | '!';
  value: SmokingStatusOption;
  operand: OperandValues.smokingStatus;
}

export interface SmokingStatusSocialHistoryOperand {
  operator: '=' | '!';
  value: SmokingStatusOption;
  operand: OperandValues.smokingStatusSocialHistory;
}

export interface SmokingCessationYearsOperand {
  operator: ConditionalOperatorType;
  value: number;
  operand: OperandValues.smokingCessationYears;
}

export interface SmokingCessationYearsSocialHistoryOperand {
  operator: ConditionalOperatorType;
  value: number;
  operand: OperandValues.smokingCessationYearsSocialHistory;
}

export interface TestResultValueOperand {
  test: TestResultValuePropertyName;
  operator: ConditionalOperatorType | 'Is Null';
  value: string | null;
  operand: OperandValues.testResultValue;
}

export interface NoTestResultDateWithinOperand {
  test: TestResultDatePropertyName;
  duration: DurationObject;
  operand: OperandValues.noTestResultDateWithin;
}

export interface BloodPressureOperand {
  values: BloodPressureValueType[];
  operand: OperandValues.bloodPressure;
}

export interface FluSeasonOperand {
  value: FluSeasonOption;
  operand: OperandValues.fluSeason;
}

export interface VaccineAdministeredOperand {
  operator: ConditionalOperatorType;
  vaccine: VaccineAdministeredPropertyName;
  value: number;
  operand: OperandValues.vaccineAdministered;
}

export interface AgeOfFirstVaccineOperand {
  operator: ConditionalOperatorType;
  vaccine: AgeOfFirstVaccinePropertyName;
  value: number;
  operand: OperandValues.ageOfFirstVaccine;
}

export interface PrimaryInsuranceNameOperand {
  caseSensitive: boolean;
  operator: PrimaryInsuranceOperatorType;
  value: string;
  operand: OperandValues.primaryInsuranceName;
}

export interface CDSSRuleVersion {
  version: number;
  createdBy: string;
  timestamp: string;
  diffs?: Diff[];
}

export interface Diff {
  // uses the "IChange" interface from jsonDiff
  // https://github.com/ltwlf/json-diff-ts/blob/master/src/jsonDiff.ts
  type: string;
  key: string;
  embeddedKey?: string | FunctionKey;
  value?: any | any[];
  oldValue?: any;
  changes?: Diff[];
}

type FunctionKey = (obj: any, getKeyName?: boolean) => any;

export interface CDSSRule {
  rule: CDSSRuleListDto;
  versions: CDSSRuleVersion[];
}
export interface CDSSRuleListDto {
  id: string;
  version: number;
  name: string;
  displayName: string;
  active: boolean;
  order: number;
  enDesc: string;
  esDesc: string;
  preventative: boolean;
  rules: Operand | undefined;
  recommendations: Recommendation[];
  createdBy?: string;
  deletedBy?: string;
  datePosted: Date;
  deletedAt?: string;
  types: ('ALERT' | 'CLINICAL' | 'FOLLOW_UP' | 'IMAGING')[];
  versions?: CDSSRuleVersion[];
}

export type CreateCDSSRule = Omit<CDSSRuleListDto, 'id' | 'version' | 'datePosted'>;

export interface Developer {
  id: number;
  uuid: string;
  date?: Date;
  firstName: string;
  lastName: string;
  genderId: number;
  genderName?: string;
  occupationId?: number | undefined;
  occupationName?: string;
  married: boolean;
  lastUpdatedDate?: Date;
  lastUpdatedBy?: string;
  tasks?: DeveloperTask[];
}

export interface DeveloperTask {
  name: string;
}

export interface OrderMetric {
  row?: number | undefined;
  assessmentItemId?: number;
  orderId?: number;
  staffName?: string;
  orderName?: string;
  orderDate: Date;
  orderAccepted: boolean;
  orderReason?: string | undefined;
  orderLocationName?: string | undefined;
  serviceLocationName?: string | undefined;
  staffId: number;
  orderReportId?: number | undefined;
  orderLocationId: number;
  providerId?: number | undefined;
  providerName?: string | undefined;
  encounterId: number;
  patientId: number;
}

export interface OrderActivityFilter {
  staffId?: number | undefined;
  primaryServiceLocationId?: number | undefined;
  dateFrom?: Date | undefined;
  dateTo?: Date | undefined;
}

export interface SelfServiceFilter {
  patientId?: number | undefined;
  days?: number | undefined;
}

export interface DurationObject {
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;
}

// Maps to the DoS Property Name on the Patient.Marketing object
export enum DoSPropertyName {
  aaaScreenDoS = 'aaaScreenDoS',
  abiDoS = 'abiDoS',
  aweDoS = 'aweDoS',
  carotidUltraSoundDoS = 'carotidUltraSoundDoS',
  chestXRayDoS = 'chestXRayDoS',
  cpeDoS = 'cpeDoS',
  dexaDoS = 'dexaDoS',
  echoDoS = 'echoDoS',
  ekgDoS = 'ekgDoS',
  eyeTelDoS = 'eyeTelDoS',
  fluFaccDoS = 'fluFaccDoS',
  glaucomaDoS = 'glaucomaDoS',
  hemoccultDoS = 'hemoccultDoS',
  hepPanelDoS = 'hepPanelDoS',
  liverUsDoS = 'liverUsDoS',
  lungScrnDoS = 'lungScrnDoS',
  mammogramDoS = 'mammogramDoS',
  microalbuminDOS = 'microalbuminDOS',
  pneumoniaVacDoS = 'pneumoniaVacDoS',
  sleepStudyDoS = 'sleepStudyDoS',
  spiroDoS = 'spiroDoS',
  stressTestDoS = 'stressTestDoS',
  testoPelDoS = 'testoPelDoS',
  thyroidUsDoS = 'thyroidUsDoS',
  wellChildDoS = 'wellChildDoS',
}

export enum VaccineAdministeredPropertyName {
  HPV = 'numberOfHPVVaccinesAdministered',
  Pneumonia = 'numberOfPneumoniaVaccinesAdministered',
  Shingrix = 'numberOfShingrixVaccinesAdministered',
}

export enum AgeOfFirstVaccinePropertyName {
  HPV = 'ageOfFirstHPVVaccine',
  Shingrix = 'ageOfFirstShringrixVaccine',
}

export enum SmokingStatusOption {
  current = 'Current smoker',
  former = 'Former smoker',
  nonSmoker = 'Non-smoker',
  other = 'Other tobacco user',
}

export enum FluSeasonOption {
  isFluSeason = 'Is Currently Flu Season',
}

export enum OperandValues {
  any = 'any',
  all = 'all',
  age = 'age',
  gender = 'gender',
  diagnosis = 'diagnosis',
  noPreviousTest = 'noPreviousTest',
  noPreviousDoS = 'noPreviousDoS',
  noTestWithin = 'noTestWithin',
  noDoSWithin = 'noDoSWithin',
  noEncounterWithin = 'noEncounterWithin',
  noTestResultDateWithin = 'noTestResultDateWithin',
  race = 'race',
  packYears = 'packYears',
  packYearsSocialHistory = 'packYearsSocialHistory',
  smokingStatus = 'smokingStatus',
  smokingStatusSocialHistory = 'smokingStatusSocialHistory',
  smokingCessationYears = 'smokingCessationYears',
  smokingCessationYearsSocialHistory = 'smokingCessationYearsSocialHistory',
  testResultValue = 'testResultValue',
  bloodPressure = 'bloodPressure',
  fluSeason = 'fluSeason',
  vaccineAdministered = 'vaccineAdministered',
  ageOfFirstVaccine = 'ageOfFirstVaccine',
  primaryInsuranceName = 'primaryInsuranceName',
}

export enum TestResultValuePropertyName {
  aagfrResultValue = 'aagfrResultValue',
  hbA1CLastResultValue = 'hbA1CLastResultValue',
  ldlLastResultValue = 'ldlLastResultValue',
  naagfrResultValue = 'naagfrResultValue',
  tResultValue = 'tResultValue',
}
export enum TestResultDatePropertyName {
  aagfrResultDate = 'aagfrResultDate',
  hbA1CLastResultDate = 'hbA1CLastResultDate',
  ldlLastResultDate = 'ldlLastResultDate',
  naagfrResultDate = 'naagfrResultDate',
  tResultDate = 'tResultDate',
}

export enum BloodPressurePropertyNames {
  bP2DiastolicValue = 'bP2DiastolicValue',
  bP2SystolicValue = 'bP2SystolicValue',
  bpDiastolicValue = 'bpDiastolicValue',
  bpSystolicValue = 'bpSystolicValue',
}

export interface BloodPressureValueType {
  value: number;
  operator: ConditionalOperatorType;
  propertyName: BloodPressurePropertyNames;
  label: 'Systolic' | 'Diastolic' | 'Systolic 2' | 'Diastolic 2';
}

export type ConditionalOperatorType = '>=' | '<=' | '>' | '<' | '=';
export const CONDITIONAL_OPERATORS: ConditionalOperatorType[] = [
  '>=',
  '<=',
  '>',
  '<',
  '=',
];

export type PrimaryInsuranceOperatorType = 'contains' | 'is' | 'not containing';
