import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import {
  PrimaryInsuranceNameOperand,
  OperandValues,
  PrimaryInsuranceOperatorType,
} from 'types';
import { OperandProps } from './constants/OperandProps';
import { Select } from 'components/mui';

const operators: PrimaryInsuranceNameOperand['operator'][] = [
  'contains',
  'is',
  'not containing',
];

export default function PrimaryInsuranceName({
  operand,
  updateOperand,
}: OperandProps<PrimaryInsuranceNameOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPrev = operand?.operand === OperandValues.primaryInsuranceName;
  if (!isPrev) return <></>;

  const handleCaseSensitiveChange = (e: SelectChangeEvent<any>) => {
    const newValue = e.target.value === 'Yes' ? true : false;
    updateOperand({ ...operand, caseSensitive: newValue });
  };

  const handleOperatorChange = (e: SelectChangeEvent<any>) => {
    updateOperand({
      ...operand,
      operator: e.target.value as PrimaryInsuranceOperatorType,
    });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, value: e.target.value });
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 4 }}>
        <div style={{ width: '50%' }}>
          <Select
            label="Case-Sensitive"
            onChange={handleCaseSensitiveChange}
            value={operand.caseSensitive ? 'Yes' : 'No'}
          >
            <MenuItem value={'No'}>{'No'}</MenuItem>
            <MenuItem value={'Yes'}>{'Yes'}</MenuItem>
          </Select>
        </div>

        <div style={{ width: '50%' }}>
          <Select
            label="Operator"
            onChange={handleOperatorChange}
            value={operand.operator}
          >
            {operators.map((o, i) => (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <TextField
        label="Insurance Name"
        onChange={handleValueChange}
        value={operand.value}
      />
    </>
  );
}
